import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import VideoList from './videoList/';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 video 组件
 */
class Video extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const videoList = (
            <div className={style.mt20} key="video_mt20">
                <VideoList totalContent={content} />
            </div>
        );

        return [videoList];
    }
}

export default errorBoundary(Video);
