import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TopNewsList from '../topNewsList';
class TopNewsFirst extends React.Component {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        return (
            <div>
                <TopNewsList content={content} />
            </div>
        );
    }
}

export default errorBoundary(TopNewsFirst);
