import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TopNewsList from './topNewsList';
import TopNewsFirst from './topNewsFirst';
import TopNewsMiddle from './topNewsMiddle';
import Chip from 'Chip';

/**
 * 定义 topNews 组件
 */
class TopNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        newTopNewsList: [],
    };

    componentDidMount() {
        const {
            content: { topNewsEditorFirst, topNewsList, topNewsEditorEnd },
        } = this.props;
        let topNewsEditorFirstLength = topNewsEditorFirst.length;
        let topNewsEditorEndLenth = topNewsEditorEnd.length;
        let topNewsListLength = 10 - topNewsEditorFirstLength - topNewsEditorEndLenth;
        let newTopNewsList = topNewsList.slice(0, topNewsListLength);

        this.setState({
            newTopNewsList,
        });
        console.log('setState');
    }
    /**
     * 渲染组件
     */
    render() {
        const { newTopNewsList } = this.state;
        const {
            content: { topNewsEditorFirst, topNewsEditorEnd, info },
        } = this.props;
        const _info = info;
        const _newTopNewsList = newTopNewsList;
        const _topNewsEditorFirst = topNewsEditorFirst;

        let _newTopNewsTotal = {
            topNewsEditorFirst: _topNewsEditorFirst,
            topNewsList: _newTopNewsList,
            info: _info,
        };

        const topNews = (
            <div className={style.topNewsList} key="topNewsList">
                <Chip
                    id="20042"
                    type="recommend"
                    title="娱乐首页-头条"
                    groupName="正文"
                    content={topNewsEditorFirst}
                    translate="handleTopNewsFirstListPicData">
                    {topNewsEditorFirst && topNewsEditorFirst.length ? (
                        <TopNewsFirst content={topNewsEditorFirst} />
                    ) : (
                        <div />
                    )}
                </Chip>

                {newTopNewsList && newTopNewsList.length ? <TopNewsMiddle content={_newTopNewsTotal} /> : ''}

                <Chip
                    id="55034"
                    type="recommend"
                    title="娱乐首页-头条-底部"
                    groupName="正文"
                    content={topNewsEditorEnd}
                    translate="handleTopNewsEndListPicData">
                    {topNewsEditorEnd && topNewsEditorEnd.length ? <TopNewsList content={topNewsEditorEnd} /> : <div />}
                </Chip>
            </div>
        );

        const topTitle = (
            <div className={style.top_title} key="top_title">
                <img src="http://p0.ifengimg.com/fe/ent_ifeng_index/images/yt_02_623c9ea6.jpg" alt="" rel={rel} />
            </div>
        );

        return [topTitle, topNews];
    }
}

export default errorBoundary(TopNews);
