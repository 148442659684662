import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import HotSpecialList from './hotSpecialList';

/**
 * 定义 热门专题 组件
 */
class HotSpecial extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const hotSpecialList = (
            <div key="hotSpecialList">
                <HotSpecialList content={content} />
            </div>
        );
        const hotSpecialTitle = (
            <div className={`${style.box_06_title} ${style.clearfix}`} key="box_06_title">
                <a href="//ent.ifeng.com/special/" target="_blank" className={style.more} rel={rel} />
                <a href="//ent.ifeng.com/special/" target="_blank" className={style.title_word} rel={rel}>
                    热点专题
                </a>
            </div>
        );

        return [hotSpecialTitle, hotSpecialList];
    }
}

export default HotSpecial;
