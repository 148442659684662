import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { jsonp } from '@ifeng-fe/ui_base';
import md5 from 'md5';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import { getCommentCount } from '../../../../../services/api';

/**
 * 定义 topNewsList 组件
 */
class TopNewsList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    state = {
        counts: [],
    };
    /**
     * 获取skey方法
     */
    getSkey = (title, url) => {
        const str = `Ifeng888${encodeURI(title)}${encodeURI(url)}`;
        const skey = md5(str);

        return skey.substr(2, 6).toLowerCase();
    };

    /**
     * 获取评论数
     */
    async componentDidMount() {
        try {
            const { content } = this.props;
            const __data = this.getData(content);
            const docUrl = __data.map(item => item.commentUrl || '');
            const counts = await getCommentCount(docUrl);
            const count = counts.map(item => item.count);

            this.setState({ counts: count });
        } catch (e) {
            console.log(e);
        }
    }
    /**
     *处理数据
     */
    getData = data => {
        try {
            const __data = data.filter((item, i) => i < 10);

            const res = __data.map(item => {
                if (item.type !== 'ad') {
                    if (item.hasOwnProperty('thumbnails')) {
                        const thu = item.thumbnails ? item.thumbnails : {};
                        const img = thu.image || {};
                        const src = img && img.length > 0 ? img[0].url : '';

                        item.src = src ? getImage(src, 160, 90) : '';
                    } else {
                        // 处理推荐位数据
                        item.src = item.thumbnail;
                    }
                }

                return item;
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 渲染组件
     */
    render() {
        const { counts } = this.state;
        const { content } = this.props;
        const __data = this.getData(content);

        const topNews = __data.map((item, index) => {
            return item.type === 'ad' ? (
                <div className={style.box_08} key={index}>
                    <div className={style.infoConOneBox}>
                        <div className={`${style.infoConOneTxt} ${style.clearfix}`}>
                            <div ref={item.ref} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={style.box_08} key={index}>
                    <div className={style.infoConOneBox}>
                        <div className={`${style.infoConOneTxt} ${style.clearfix}`}>
                            <div className={style.infoConOneBox_pic}>
                                <a href={item.url} target="_blank" rel={rel}>
                                    <img src={item.src} />
                                </a>
                            </div>
                            <div className={style.infoConOneBox_word}>
                                <h3 className={`${style.vBlack} ${style.vPoz}`}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        {item.title}
                                    </a>
                                </h3>
                            </div>
                            <div className={style.pl}>
                                <a
                                    href={`//gentie.ifeng.com/view.html?docUrl=${item.commentUrl || ''}&docName=${
                                        item.title
                                    }&skey=${this.getSkey(item.title, item.url)}&pcUrl&=${item.url}`}
                                    target="_blank"
                                    rel={rel}>
                                    {counts[index]}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return [topNews];
    }
}

export default TopNewsList;
