import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import errorBoundary from '@ifeng-fe/errorBoundary';
/**
 * 定义 右侧栏目模板 组件
 */
class ColumnTpl extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    /**
     *处理数据
     */
    getData = data => {
        try {
            const __data = data.filter((item, i) => i < 4);

            const res = __data.map(item => {
                if (item.hasOwnProperty('thumbnails')) {
                    const thu = item.thumbnails ? item.thumbnails : {};
                    const img = thu.image || {};
                    const src = img && img.length > 0 ? img[0].url : '';

                    item.src = src ? getImage(src, 299, 210) : '';
                } else {
                    // 处理推荐位数据
                    item.src = item.thumbnail;
                }

                return item;
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    };
    render() {
        const { content } = this.props;
        const _data = content.data;
        const specialTitle = content.title;
        const __data = this.getData(_data);
        const specialTpl = __data.map((item, index) => {
            if (index === 0) {
                return (
                    <li className={style.bg03} key={index}>
                        <div className={style.box_099_02}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={item.src} alt={item.title} title={item.title} />
                            </a>
                            <div className={style.text_bg02} />
                            <div className={style.text_03}>
                                <a href={item.url} target="_blank" rel={rel}>
                                    {specialTitle}
                                </a>
                            </div>
                            <div className={style.text_04}>
                                <a href={item.url} target="_blank" rel={rel}>
                                    {item.title}
                                </a>
                            </div>
                        </div>
                    </li>
                );
            } else {
                return (
                    <li className={index % 2 === 0 ? style.bg03 : style.bg04} key={index}>
                        <div className={style.box_099_01}>
                            <h3>
                                <a href={item.url} target="_blank" rel={rel}>
                                    {item.title}
                                </a>
                            </h3>
                        </div>
                    </li>
                );
            }
        });
        let specialBox = (
            <div className={style.box_099} key="box_099">
                <ul>{specialTpl}</ul>
            </div>
        );

        return [specialBox];
    }
}

export default errorBoundary(ColumnTpl);
