import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import errorBoundary from '@ifeng-fe/errorBoundary';
/**
 * 定义 明星焦点图 组件
 */
class SuperFocus extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };
    /**
     *处理数据
     */
    getData = data => {
        try {
            const res = data.map(item => {
                if (item.hasOwnProperty('thumbnails')) {
                    const thu = item.thumbnails ? item.thumbnails : {};
                    const img = thu.image || {};
                    const src = img && img.length > 0 ? img[0].url : '';

                    item.src = src ? getImage(src, 650, 280) : '';
                } else {
                    // 处理推荐位数据
                    item.src = item.thumbnail ? getImage(item.thumbnail, 650, 280) : '';
                }

                return item;
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    };
    render() {
        const { content } = this.props;
        const __data = this.getData(content) || [];

        const superFocus = __data.map((item, index) => {
            return (
                <div className={style.box_07} key={index}>
                    <div className={style.box_07_a}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.src} alt={item.title} title={item.title} />
                        </a>

                        <div className={style.bag} />
                        <div className={style.text_02}>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        </div>
                    </div>
                </div>
            );
        });

        return [superFocus];
    }
}

export default errorBoundary(SuperFocus);
