import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import errorBoundary from '@ifeng-fe/errorBoundary';
/**
 * 定义 右侧栏目模板 组件
 */
class ColumnTplShow extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;
        const specialTpl = content.map((item, index) => {
            return (
                <li className={style.bg03} key={index}>
                    <div className={style.box_099_02}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.thumbnail} alt={item.title} title={item.title} />
                        </a>
                        <div className={style.text_bg02} />
                        <div className={style.text_03}>
                            <a href={item.url} target="_blank" rel={rel}>
                                演出推荐
                            </a>
                        </div>
                        <div className={style.text_04}>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        </div>
                    </div>
                </li>
            );
        });
        let specialBox = (
            <div className={style.box_099} key="box_099">
                <ul>{specialTpl}</ul>
            </div>
        );

        return [specialBox];
    }
}

export default errorBoundary(ColumnTplShow);
