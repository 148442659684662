import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
// import LiDom from '../liDom';

/**
 * 定义 hotSpecialList 组件
 */
class hotSpecialList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        data: [],
    };

    box = React.createRef();

    async componentDidMount() {
        try {
            const {
                content: { hotSpecial, info },
            } = this.props;
            const infoAd = info.hotSpecialAd;

            // const infoAd = {
            //     data: {},
            //     preload: '',
            //     callback: function(data, dom) {
            //         // insert 为我们自定义的广告插入方法
            //         console.log('dom---', dom);
            //         // index 为广告方想要插入的位置，这里也可以改为数组进行批量操作，
            //     },
            // };

            this.setState({
                data: [...hotSpecial],
            });

            const callback = await handleAd(infoAd);

            callback(infoAd.data, this.box.current);
        } catch (e) {
            console.error(e);
        }
    }
    /**
     * 处理数据
     */
    getData = data => {
        try {
            const __data = data.filter((item, i) => i < 4);

            return __data;
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;
        const __data = this.getData(data);
        /* eslint-disable no-nested-ternary */
        // const dataList = __data.map((item, index) => {
        //     if (__data && __data.length) {
        //         return index === 1 ? (
        //             <Chip
        //                 key="key_20044"
        //                 id="20044"
        //                 type="recommend"
        //                 title="娱乐首页-热点专题"
        //                 groupName="正文"
        //                 content={__data}
        //                 translate="handleSpecialListData">
        //                 <LiDom />
        //             </Chip>
        //         ) : index === 2 ? (
        //             <li key={index} ref={this.box}>
        //                 <a href={item.url} target="_blank" rel={rel}>
        //                     {item.title}
        //                 </a>
        //             </li>
        //         ) : (
        //             <li key={index}>
        //                 <a href={item.url} target="_blank" rel={rel}>
        //                     {item.title}
        //                 </a>
        //             </li>
        //         );
        //     } else {
        //         return index === 1 ? (
        //             <React.Fragment>
        //                 <Chip
        //                     key="key_20044"
        //                     id="20044"
        //                     type="recommend"
        //                     title="娱乐首页-热点专题"
        //                     groupName="正文"
        //                     content={__data}
        //                     translate="handleSpecialListData">
        //                     <div />
        //                 </Chip>
        //                 <li key={index} ref={this.box}>
        //                     <a href={item.url} target="_blank" rel={rel}>
        //                         {item.title}
        //                     </a>
        //                 </li>
        //             </React.Fragment>
        //         ) : index === 2 ? (
        //             <li key={index} ref={this.box}>
        //                 <a href={item.url} target="_blank" rel={rel}>
        //                     {item.title}
        //                 </a>
        //             </li>
        //         ) : (
        //             <li key={index}>
        //                 <a href={item.url} target="_blank" rel={rel}>
        //                     {item.title}
        //                 </a>
        //             </li>
        //         );
        //     }
        // });
        const urlList =
            __data && __data.length ? (
                <Chip
                    key="key_20044"
                    id="20044"
                    type="recommend"
                    title="娱乐首页-热点专题"
                    groupName="正文"
                    content={__data}
                    translate="handleSpecialListData">
                    <ul key="hotSpecial" className={`${style.box_06} ${style.clearfix}`}>
                        {__data.map((item, index) => {
                            return (
                                <li key={index} ref={index === 2 ? this.box : undefined}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        {item.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </Chip>
            ) : (
                ''
            );

        return [urlList];
    }
}

export default errorBoundary(hotSpecialList);
