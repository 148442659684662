import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import Slides from '@ifeng-fe/ui_pc_slides';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';
import { Event } from '@ifeng-fe/ui_base';

const event = new Event();

class SliderInner extends React.PureComponent {
    static propTypes = {
        totalContent: PropTypes.object,
    };

    state = {
        data: [],
    };

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const {
                totalContent: { slider, info },
            } = this.props;
            const infoAd = info.sliderAd;

            // const infoAd = {
            //     data: {},
            //     preload: '',
            //     callback: function(data, event, insert) {
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', function(data) {
            //             // data 为我们触发方法时传参值
            //             insert([], [1], function(dom) {
            //                 console.log('Adddddd');
            //                 // index 为广告方想要插入的位置，这里也可以改为数组进行批量操作，
            //             });
            //         });
            //     },
            // };

            this.setState({
                data: [...slider],
            });

            const callback = await handleAd(infoAd);

            callback(infoAd.data, event, this.insert);

            event.trigger('init', { slider });
        } catch (e) {
            console.error(e);
        }
    }

    getData = data => {
        try {
            const __data = data.filter((item, i) => i < 6);
            const res = __data.map(item => {
                if (item.type !== 'ad') {
                    if (item.hasOwnProperty('thumbnails')) {
                        const thu = item.thumbnails ? item.thumbnails : {};
                        const img = thu.image || {};
                        const src = img && img.length > 0 ? img[0].url : '';

                        item.src = src ? getImage(src, 650, 360) : '';
                    } else {
                        // 处理推荐位数据
                        item.src = item.thumbnail;
                    }
                }

                return item;
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    };

    sliderTmpl = item => {
        return item.type === 'ad' ? (
            <div className={style.bigPic04}>
                <div ref={item.ref} />
            </div>
        ) : (
            <div className={style.bigPic04}>
                <a href={item.url} target="_blank" rel={rel} data-nomask>
                    <img src={item.src} width="650" height="360" alt={item.title} className={style.trans} />
                </a>
                <div className={style.text} />
                <p className={style.cWhite}>
                    <a href={item.url} target="_blank" rel={rel} data-innermask>
                        {item.title}
                    </a>
                </p>
            </div>
        );
    };

    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;
        // const { content } = this.props;
        // const newData1 = data.shift();
        // const newData2 = content.concat(data);
        const __data = this.getData(data);

        const config = {
            arrows: 'hover',
            autoplay: false,
            direction: 'forward',
            axis: 'horizonta',
            dotsAction: 'hover',
            sliderTmpl: this.sliderTmpl,
            dotCurrentStyle: style.current,
        };

        return (
            <div className={style.fpic06}>{__data.length > 0 ? <Slides content={__data} config={config} /> : ''}</div>
        );
    }
}

export default errorBoundary(SliderInner);
