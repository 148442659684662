import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import TopNewsList from '../topNewsList';
import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';
import { Event } from '@ifeng-fe/ui_base';

const event = new Event();

class TopNewsMiddle extends React.Component {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        topNewsEditorFirst: [],
        data: [],
        topNewsEditorEnd: [],
    };
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            const infoData = [...data];
            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        try {
            const {
                content: { topNewsEditorFirst, topNewsList, info },
            } = this.props;
            const infoAd = info.topNewsAd;

            // const infoAd = {
            //     data: {},
            //     preload: '',
            //     callback: function(data, event, insert) {
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', function(data) {
            //             // data 为我们触发方法时传参值
            //             insert([], [3], function(dom) {
            //                 console.log('Adddddd');
            //                 // index 为广告方想要插入的位置，这里也可以改为数组进行批量操作，
            //             });
            //         });
            //     },
            // };

            this.setState({
                topNewsEditorFirst: [...topNewsEditorFirst],
                data: [...topNewsList],
            });

            const callback = await handleAd(infoAd);

            callback(infoAd.data, event, this.insert);

            event.trigger('init', { rec_len: topNewsEditorFirst.length });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * 渲染组件
     */
    render() {
        const { data } = this.state;
        const {
            content: { topNewsEditorFirst, topNewsEditorEnd },
        } = this.props;
        let topNewsEditorFirstLength = topNewsEditorFirst && topNewsEditorFirst.length ? topNewsEditorFirst.length : 0;
        let topNewsEditorEndLength = topNewsEditorEnd && topNewsEditorEnd.length ? topNewsEditorEnd.length : 0;
        let listLength = 10 - topNewsEditorFirstLength - topNewsEditorEndLength;
        const __data = data.filter((item, i) => i < listLength);

        return __data.length > 0 ? <TopNewsList content={__data} /> : '';
    }
}

export default errorBoundary(TopNewsMiddle);
