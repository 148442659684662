import React from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import { handleAd } from '@ifeng-fe/container_channel/common/client/utils/utils';
import md5 from 'md5';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getCommentCount } from '../../../../services/api';
import { Event } from '@ifeng-fe/ui_base';

const event = new Event();

/**
 * 定义 明星信息流 组件
 */
 class SuperContent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        index: PropTypes.string,
    };
    state = {
        counts: [],
        moreData: [
            {
                des: '明星更多',
                url: '//ent.ifeng.com/shanklist/4-20074-',
            },
            {
                des: '电影更多',
                url: '//ent.ifeng.com/shanklist/4-20075-',
            },
            {
                des: '电视更多',
                url: '//ent.ifeng.com/shanklist/4-20076-',
            },
            {
                des: '音乐更多',
                url: '//ent.ifeng.com/shanklist/4-20077-',
            },
        ],
        data: [],
    };

    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    /**
     * 获取skey方法
     */
    getSkey = (title, url) => {
        const str = `Ifeng888${encodeURI(title)}${encodeURI(url)}`;
        const skey = md5(str);

        return skey.substr(2, 6).toLowerCase();
    };

    /**
     * 获取评论数 获取广告
     */
    async componentDidMount() {
        try {
            const {
                content: { topData, data, info },
            } = this.props;

            // 排重，data数据里面过滤掉topData的数据
            let newData = [...data];

            if (topData && topData.length) {
                const id = topData[0].id;

                newData = data.filter((item, i) => item.id !== id);
            }

            // 获取评论数
            const __data = this.getData(newData) || [];
            const docUrl = __data.map(item => item.commentUrl || '');
            const counts = await getCommentCount(docUrl);
            const count = counts.map(item => item.count);

            this.setState({ counts: count });

            // 插入广告
            const infoAd = info.infoAd;
            // const infoAd = {
            //     data: {},
            //     preload: '',
            //     callback: function(data, event, insert) {
            //         // insert 为我们自定义的广告插入方法
            //         event.on('init', function(data) {
            //             // data 为我们触发方法时传参值
            //             insert([], [1], function(dom) {
            //                 console.log('Adddddd');
            //                 // index 为广告方想要插入的位置，这里也可以改为数组进行批量操作，
            //             });
            //         });
            //     },
            // };

            this.setState({
                data: [...newData],
            });

            const callback = await handleAd(infoAd);

            callback(infoAd.data, event, this.insert);

            event.trigger('init', { newData });
        } catch (e) {
            console.log(e);
        }
    }
    /**
     *处理数据
     */
    getData = data => {
        try {
            const __data = data.filter((item, i) => i < 5);
            const res = __data.map(item => {
                if (item.type !== 'ad') {
                    if (item.hasOwnProperty('thumbnails')) {
                        const thu = item.thumbnails ? item.thumbnails : {};
                        const img = thu.image || {};
                        const src = img && img.length > 0 ? img[0].url : '';

                        item.src = src ? getImage(src, 160, 90) : '';
                    }
                }

                return item;
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const { index } = this.props;
        const { counts, moreData, data } = this.state;
        const __data = this.getData(data) || [];
        const moreIndex = parseInt(index);

        const superContent = __data.map((item, index) => {
            return item.type === 'ad' ? (
                <div className={style.box_08} key={index}>
                    <div className={`${style.infoConOneBox}`}>
                        <div className={`${style.infoConOneBox}  ${style.pt10}`}>
                            <div className={`${style.infoConOneTxt} ${style.clearfix}`}>
                                <div ref={item.ref} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={style.box_08} key={index}>
                    <div className={`${style.infoConOneBox}`}>
                        <div className={`${style.infoConOneBox}  ${style.pt10}`}>
                            <div className={`${style.infoConOneTxt} ${style.clearfix}`}>
                                <div className={style.infoConOneBox_pic}>
                                    <a href={item.url} target="_blank" rel={rel}>
                                        <img src={item.src} />
                                    </a>
                                </div>
                                <div className={style.infoConOneBox_word}>
                                    <h3 className={`${style.vBlack} ${style.vPoz}`}>
                                        <a href={item.url} target="_blank" rel={rel}>
                                            {item.title}
                                        </a>
                                    </h3>
                                </div>
                                <div className={style.pl}>
                                    <a
                                        href={`//gentie.ifeng.com/view.html?docUrl=${item.commentUrl || ''}&docName=${
                                            item.title
                                        }&skey=${this.getSkey(item.title, item.url)}&pcUrl&=${item.url}`}
                                        target="_blank"
                                        rel={rel}>
                                        {counts[index]}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        const supreMore = moreData.map((item, index) => {
            let result = null;

            if (index === moreIndex - 1) {
                result = (
                    <a href={item.url} target="_blank" rel={rel} key={index}>
                        更多新闻
                    </a>
                );
            }

            return result;
        });

        const superMoreDom = (
            <div className={style.more_02} key="more_02">
                {supreMore}
            </div>
        );

        return [superContent, superMoreDom];
    }
}

export default errorBoundary(SuperContent);
