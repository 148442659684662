import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import { getImage } from '@ifeng-fe/container_channel/common/client/utils/cutImg';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 videoList 组件
 */
class VideoList extends React.PureComponent {
    static propTypes = {
        totalContent: PropTypes.array,
    };
    /**
     *处理数据
     */
    getData = data => {
        try {
            const __data = data.filter((item, i) => i < 6);

            const res = __data.map(item => {
                if (item.hasOwnProperty('thumbnails')) {
                    const thu = item.thumbnails ? JSON.parse(item.thumbnails) : {};
                    const img = thu.image || {};
                    const src = img && img.length > 0 ? img[0].url : '';

                    item.src = src ? getImage(src, 120, 92) : '';
                } else {
                    // 处理推荐位数据
                    item.src = item.thumbnail;
                }

                return item;
            });

            return res;
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 渲染组件
     */
    render() {
        const { totalContent } = this.props;
        const __data = this.getData(totalContent);

        const entVideoTpl = __data.map((item, index) => {
            if (index === 0 || index === 3) {
                return (
                    <div className={`${style.fl} ${style.FVCPic}`} key={index}>
                        <a href={item.url} target="_blank" rel={rel}>
                            <img src={item.src} alt={item.title} title={item.title} />
                        </a>
                        <div className={style.FVCPicBtn}>
                            <a href={item.src} target="_blank" rel={rel} />
                        </div>
                    </div>
                );
            } else {
                return (
                    <ul className={`${style.FVCTxt} ${style.fl}`} key={index}>
                        {index === 1 || index === 4 ? (
                            <li key={index}>
                                <span>
                                    <a target="_blank" href="http://ent.ifeng.com/">
                                        {index === 1 ? '娱乐' : '独家'}
                                    </a>
                                </span>
                                <a target="_blank" href={item.url}>
                                    {item.title}
                                </a>
                            </li>
                        ) : (
                            <li key={index}>
                                <a target="_blank" href={item.url}>
                                    {item.title}
                                </a>
                            </li>
                        )}
                    </ul>
                );
            }
        });

        const videoList = (
            <div className={`${style.FNewVidCon} ${style.clearfix}`} key="FNewVidCon">
                {entVideoTpl}
            </div>
        );

        return [videoList];
    }
}

export default errorBoundary(VideoList);
