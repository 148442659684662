import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import style from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { addEventListener } from '@ifeng-fe/ui_base';
import rel from '@ifeng-fe/container_channel/common/client/utils/rel';
import Ad from '@ifeng-fe/ui_pc_ad';
import Header from './header';
import Navigation from './navigation';
import Slider from './slider/';
import TopNews from './topNews/';
import SpecialSlider from './specialSlider/';
import Video from './video/';
import HotSpecial from './hotSpecial';
import SuperFocus from './superFocus';
import SuperContent from './superContent';
import ColumnTpl from './columnTpl';
import ColumnTplShow from './columnTplShow';
import BottomFooter from './footer';
import Cooperation from './cooperation';
import QrCode from './qrCode';
import BottomAffix from './bottomAffix';
class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
        this.unHandleClick = addEventListener(document, 'click', this.handleClick);
    }

    componentWillUnmount() {
        this.unHandleClick();
    }
    // 全站渠道标记传递，文章页的任意跳转
    handleClick = e => {
        const tag = this.getATag(e.target);

        if (!tag) return;

        let localHref =
            (tag.attributes['sign-trs-href'] ? tag.attributes['sign-trs-href'].value : '') ||
            (tag.attributes['href'] ? tag.attributes['href'].value : '');

        if (
            localHref &&
            localHref !== 'undefined' &&
            localHref !== '' &&
            localHref !== '#' &&
            localHref.indexOf('javascript:') === -1
        ) {
            localHref = localHref.trim();
            const localSearch = location.search;
            const localHash = location.hash;

            tag.setAttribute('sign-trs-href', localHref);

            let newUrl = '';
            let inWhitelist = false;
            const whitelist = ['//dol.deliver.ifeng.com/'];

            for (let i = 0, len = whitelist.length; i < len; i++) {
                if (localHref.indexOf(whitelist[i]) > -1) {
                    inWhitelist = true;
                }
            }
            // 传递下划线开头的统计
            const curSrc = this.getParam(localSearch, localHash);

            if ((localSearch || localHash) && curSrc && !inWhitelist) {
                if (localHref.indexOf('?') > -1) {
                    newUrl = `${localHref}&${curSrc}`;
                } else {
                    newUrl = `${localHref}?${curSrc}`;
                }

                tag.setAttribute('href', newUrl);
            }
        }
    };

    getATag = tag => {
        if (!tag) {
            return null;
        }

        if (tag.tagName !== 'A') {
            return this.getATag(tag.parentElement);
        } else {
            return tag;
        }
    };

    getSign = (localSearch, ret) => {
        const localSearchArr = localSearch.split('#');

        for (let i = 0; i < localSearchArr.length; i++) {
            const localParam = localSearchArr[i];

            if (localParam.indexOf('_') === -1) continue;

            const localParamArr = localParam.split('?');

            for (let j = 0; j < localParamArr.length; j++) {
                const localParam2 = localParamArr[j];

                if (localParam2.indexOf('_') === -1) continue;

                const localParam2Arr = localParam2.split('&');

                for (let m = 0; m < localParam2Arr.length; m++) {
                    const localParam3 = localParam2Arr[m];

                    if (localParam3.indexOf('_') === -1) continue;

                    const localParam3Arr = localParam3.split('|');

                    for (let k = 0; k < localParam3Arr.length; k++) {
                        const localParam4 = localParam3Arr[k];

                        if (localParam4.indexOf('_') !== 0) continue;

                        if (ret === '') {
                            ret += localParam4;
                        } else {
                            ret = `${ret}&${localParam4}`;
                        }
                    }
                }
            }
        }

        return ret;
    };

    getParam = (localSearch, localHash) => {
        let ret = '';

        if (localSearch.indexOf('_zbs') > -1) {
            ret = this.getSign(localSearch, ret);
        }
        if (localHash.indexOf('_zbs') > -1) {
            ret = this.getSign(localHash, ret);
        }

        return ret;
    };

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        // 头部
        const headerData = {
            nav: content.nav,
            search: content.search,
            topAd: content.topAd,
            logo: content.logo,
            channelAd: content.channelAd,
        };

        // 导航
        const navigationData = {
            nav: content.navigation,
            subNav: content.subNavigation,
        };

        // 轮播图：编辑可以干预第一条轮播图，如果推荐位中没有数据则全部自动抓取
        const _slider = content.slider ? [...content.slider] : [];
        const sliderEditor = content.sliderEditor || [];

        if (sliderEditor && sliderEditor.length) {
            if (sliderEditor.length === 1) {
                _slider.splice(0, 0, sliderEditor[0]); // 第一帧
            }
        }
        const sliderData = {
            slider: _slider,
            info: {
                sliderAd: content.sliderAd,
            },
        };

        // 要闻区：编辑可以干预1、2,10条，如果推荐位中没有数据则全部自动抓取
        const topNews = {
            topNewsEditorFirst: content.topNewsEditor || [], // 前两条
            topNewsList: content.topNews || [], // 精品池数据
            topNewsEditorEnd: content.topNewsEditorEnd || [], // 最后一条
            info: {
                topNewsAd: content.topNewsAd, // 广告
            },
        };
        // 右侧专题滚动竖图
        // const specialSlider = content.specialSlider || [];
        const specialSliderData = {
            specialSlider: content.specialSlider || [],
            info: {
                specialSliderAd: content.specialSliderAd,
            },
        };

        // 视频区域：娱乐视频、娱乐独家
        const videoData = content.entVideo || [];

        // 热点专题区域
        let _hotSpecialEditor = content.hotSpecialEditor ? [...content.hotSpecialEditor] : [];

        // if (hotSpecialEditor && hotSpecialEditor.length) {
        //     _hotSpecial.splice(1, 0, hotSpecialEditor[0]); // 第三条
        // }

        const hotSpecialData = {
            hotSpecial: _hotSpecialEditor,
            info: {
                hotSpecialAd: content.hotSpecialAd, // 广告
            },
        };

        // 明星区域
        const mingXingTop = content.mingXingTop || [];
        const mingXingContent = content.mingXingContent || [];
        const mingXingContentData = {
            topData: mingXingTop,
            data: mingXingContent,
            info: {
                infoAd: content.mingXingAd, // 广告
            },
        };

        // 电影区域
        const movieTop = content.movieTop || [];
        const movieContent = content.movieContent || [];
        const movieContentData = {
            topData: movieTop,
            data: movieContent,
            info: {
                infoAd: content.movieAd, // 广告
            },
        };

        // 电视区域
        const tvShowTop = content.tvShowTop || [];
        const tvShowContent = content.tvShowContent || [];
        const tvShowContentData = {
            topData: tvShowTop,
            data: tvShowContent,
            info: {
                infoAd: content.tvShowAd, // 广告
            },
        };

        // 音乐区域
        const musicTop = content.musicTop || [];
        const musicContent = content.musicContent || [];
        const musicContentData = {
            topData: musicTop,
            data: musicContent,
            info: {
                infoAd: content.musicAd, // 广告
            },
        };

        // 上升星座
        let constellation = {
            data: content.constellation || [],
            title: '上升星座',
        };

        // idolife星生活
        let idolife = {
            data: content.idolife || [],
            title: '星生活',
        };

        // 非常道
        let alternative = {
            data: content.alternative || [],
            title: '非常道',
        };
        // 凤凰公映礼
        let publicShow = {
            data: content.publicShow || [],
            title: '凤凰公映礼',
        };

        // 娱论导向
        let entertainment = {
            data: content.entertainment || [],
            title: '娱论导向',
        };
        // TV哔
        let tvBi = {
            data: content.tvBi || [],
            title: 'TV哔',
        };

        // 电影大爆炸
        let movieBang = {
            data: content.movieBang || [],
            title: '电影引力波',
        };
        // 演出推荐
        let showRecom = content.showRecom;

        // footer
        const footerData = {
            bottomAd: content.bottomAd,
            footer: content.footer,
        };

        // 底部合作
        const cooperation = content.cooperation;

        // 凤凰新闻二维码
        const qrCode = content.qrCode;

        const bottomAffixData = {
            floatAd1: content.floatAd1,
            floatAd2: content.floatAd2,
            floatAd3: content.floatAd3,
            floatAd4: content.floatAd4,
            floatAd5: content.floatAd5,
        };

        return (
            <div className={style.ip_col}>
                <Header content={headerData} />
                <Navigation content={navigationData} />
                <div className={style.space20} />
                <div className={`${style.col_w1000} clearfix`}>
                    <div className={style.col_left}>
                        {/* 轮播图 */}
                        <Chip
                            key="key_20041"
                            id="20041"
                            type="recommend"
                            title="娱乐首页-轮播图"
                            groupName="正文"
                            content={sliderEditor}
                            translate="handleSliderListData">
                            <Slider totalContent={sliderData} />
                        </Chip>

                        {/* 要闻区 */}
                        <TopNews content={topNews} />
                    </div>
                    <div className={style.col_right}>
                        {/* 右侧专题竖图滚动 */}
                        <Chip
                            key="key_55033"
                            id="55033"
                            type="recommend"
                            title="娱乐首页-首屏右侧轮播"
                            groupName="正文"
                            content={content.specialSlider}
                            translate="handleSpecialSlider">
                            <SpecialSlider totalContent={specialSliderData} />
                        </Chip>

                        {/* 右侧视频板块 */}
                        <Chip
                            key="key_55035"
                            id="55035"
                            type="recommend"
                            title="娱乐首页-右侧图文两条"
                            groupName="正文"
                            content={videoData}
                            translate="handleVideoList">
                            <Video />
                        </Chip>
                        <div className={style.space20} />

                        {/* 右侧热点专题 */}
                        <HotSpecial content={hotSpecialData} />

                        <div className={style.space10} />
                        <NoSSR onSSR={<div className={style.ad_box_1} />}>
                            <Ad content={content.asideAd1} />
                        </NoSSR>
                        <NoSSR onSSR={<div className={style.ad_box_1} />}>
                            <Ad content={content.asideAd6} />
                        </NoSSR>
                    </div>
                </div>
                {/* 明星区 */}
                <div className={`${style.col_w1000} clearfix`}>
                    <div className={style.col_title}>
                        <a href="//ent.ifeng.com/shanklist/4-20074-" target="_blank" rel={rel}>
                            明星
                        </a>
                    </div>
                    <div className={style.clearfix}>
                        <div className={style.col_left}>
                            {/* 明星信息流 */}
                            <SuperFocus content={mingXingTop} />
                            <SuperContent content={mingXingContentData} index="1" />
                        </div>
                        <div className={style.col_right}>
                            {/* 广告和栏目 */}
                            <NoSSR onSSR={<div className={style.ad_box_2} />}>
                                <Ad content={content.asideAd2} />
                            </NoSSR>
                            <div className={style.space28} />

                            {/* 上升星座 */}
                            <ColumnTpl content={constellation} />

                            <div className={style.space30} />
                            {/* idolife星生活 */}
                            <ColumnTpl content={idolife} />
                        </div>
                    </div>
                </div>
                {/* 娱乐首页通栏01 */}
                <NoSSR onSSR={<div className={style.banner_box} />}>
                    <Ad content={content.bannerAd01} />
                </NoSSR>

                {/* 电影 */}
                <div className={`${style.col_w1000} clearfix`}>
                    <div className={style.col_title}>
                        <a href="//ent.ifeng.com/shanklist/4-20075-" target="_blank" rel={rel}>
                            电影
                        </a>
                    </div>
                    <div className={style.clearfix}>
                        <div className={style.col_left}>
                            {/* 电影信息流 */}
                            <SuperFocus content={movieTop} />
                            <SuperContent content={movieContentData} index="2" />
                        </div>
                        <div className={style.col_right}>
                            {/* 广告和栏目 */}
                            <NoSSR onSSR={<div className={style.ad_box_2} />}>
                                <Ad content={content.asideAd3} />
                            </NoSSR>
                            <div className={style.space28} />
                            {/* 非常道 */}
                            <ColumnTpl content={alternative} />
                            <div className={style.space30} />
                            {/* 凤凰公映礼 */}
                            <ColumnTpl content={publicShow} />
                        </div>
                    </div>
                </div>
                {/* 电视 */}
                <div className={`${style.col_w1000} clearfix`}>
                    <div className={style.col_title}>
                        <a href="//ent.ifeng.com/shanklist/4-20076-" target="_blank" rel={rel}>
                            电视
                        </a>
                    </div>
                    <div className={style.clearfix}>
                        <div className={style.col_left}>
                            {/* 电视信息流 */}
                            <SuperFocus content={tvShowTop} />
                            <SuperContent content={tvShowContentData} index="3" />
                        </div>
                        <div className={style.col_right}>
                            <div className={style.space28} />
                            {/* 娱论导向 */}
                            <ColumnTpl content={entertainment} />
                            <div className={style.space30} />
                            {/* TV哔 */}
                            <ColumnTpl content={tvBi} />
                        </div>
                    </div>
                </div>
                {/* 音乐 */}
                <div className={`${style.col_w1000} clearfix`}>
                    <div className={style.col_title}>
                        <a href="//ent.ifeng.com/shanklist/4-20077-" target="_blank" rel={rel}>
                            音乐
                        </a>
                    </div>
                    <div className={style.clearfix}>
                        <div className={style.col_left}>
                            {/* 音乐信息流 */}
                            <SuperFocus content={musicTop} />
                            <SuperContent content={musicContentData} index="4" />
                        </div>
                        <div className={style.col_right}>
                            <div className={style.space28} />
                            {/* 电影大爆炸 */}
                            <ColumnTpl content={movieBang} />
                            <div className={style.space30} />
                            {/* 演出推荐 */}
                            <Chip
                                key="key_30001_01"
                                id="30001"
                                type="recommend"
                                title="演出推荐"
                                groupName="正文"
                                content={showRecom}
                                translate="handleShowListData">
                                <ColumnTplShow />
                            </Chip>
                        </div>
                    </div>
                </div>
                <div className={style.space28} />
                <div className={style.foot}>
                    <div className={style.col_w1000}>
                        <NoSSR>
                            <Chip
                                key="key_25015"
                                id="25015"
                                type="static"
                                title="底部合作链接"
                                groupName="底部合作链接"
                                content={cooperation}>
                                <Cooperation />
                            </Chip>
                        </NoSSR>
                        <BottomFooter content={footerData} />
                    </div>
                </div>
                <NoSSR>
                    <Chip
                        key="key_30047"
                        id="30047"
                        type="struct"
                        title="二维码"
                        groupName="二维码"
                        position="relative"
                        content={qrCode}>
                        <QrCode />
                    </Chip>
                </NoSSR>
                <Ad content={content.bottomAd01} />
                <NoSSR>
                    <BottomAffix content={bottomAffixData} />
                </NoSSR>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default Layout;
