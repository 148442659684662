import { jsonp } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// 获取文章评论数
export const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

/**
 * 娱乐下的子栏目-热推栏目
 * @param {String} columnType 栏目类型
 * @param {Number} pageNum 当前页
 */
export const getEntColumnData = async (columnType, pageNum) => {
    const data = await jsonp(`${apiBaseUrl}/content/ent/hotColumn/${columnType}/${pageNum}/getEntColumnData`, {
        jsonpCallback: 'getEntColumnData',
        cache: false,
    });

    return data.data ? data.data : null;
};

/**
 * 娱乐下的子栏目-找热点
 * @param {Number} pageNum 当前页
 */
export const getChaseHotData = async pageNum => {
    const data = await jsonp(`${apiBaseUrl}/content/ent/chaseHot/${pageNum}/getChaseHotData`, {
        jsonpCallback: 'getChaseHotData',
        cache: false,
    });

    return data.data ? data.data : null;
};

/**
 * 娱乐下的子栏目-找乐子
 * @param {Number} pageNum 当前页
 */
export const getFindHappyData = async pageNum => {
    const data = await jsonp(`${apiBaseUrl}/content/ent/findHappy/${pageNum}/getFindHappyData`, {
        jsonpCallback: 'getFindHappyData',
        cache: false,
    });

    return data.data ? data.data : null;
};

/**
 * 娱乐下的子栏目-找乐子-播放数和点赞数
 * @param {Number} key 视频guid
 */
export const getplayAndZanData = async key => {
    const data = await jsonp(`//survey.news.ifeng.com/api/getaccumulatorweight?${key}&serviceid=1&format=js`, {
        jsonpCallback: 'getplayAndZanData',
        cache: false,
    });

    return data ? data : null;
};

export const getYoungDataByPage = async (page = 2, type) => {
    return await jsonp(`${apiBaseUrl}/content/ent/getYoungData/${type}/${page}/getYoungData`, {
        jsonpCallback: 'getYoungData',
        cache: false,
    });
};
